import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { Global, css } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';
import SEO from '../components/seo';
import PortableText from '../components/portable-text';

export default function FourZeroFour({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setLogoColorDark(true);
    theme.setMenuColorDark(true);
    theme.setBackgroundColor(null);
  }, []);

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: var(--gutter);
        padding: 0 var(--margin);
        margin-top: 30rem;

        @media (max-width: 900px) {
          margin-top: 14rem;
        }
      `}
    >
      <h1
        className="type--large"
        css={css`
          grid-column-start: 3;
          grid-column-end: span 8;
          font-weight: normal;
          text-align: center;
          margin-bottom: 25rem;

          @media (max-width: 900px) {
            grid-column: span 12;
          }
        `}
      >Page Not Found</h1>
    </div>
  )
}
